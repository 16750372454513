import { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const GoogleCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Entering into callback...");

        // Check if the page has already reloaded after setting tokens
        const alreadyReloaded = localStorage.getItem('alreadyReloaded');

        if (!alreadyReloaded) {
            // Extract the query parameters from the hash (after #)
            const hash = window.location.hash;
            const params = new URLSearchParams(hash.split('?')[1]); // Get the query part after '?'

            const accessToken = params.get('access');
            const refreshToken = params.get('refresh');
            const email = params.get('email');
            const fullName = params.get('full_name');

            console.log("Tokens received from Google:", { accessToken, refreshToken, email, fullName });

            if (accessToken && refreshToken) {
                // Store the tokens in localStorage
                localStorage.setItem('authTokens', JSON.stringify({ access: accessToken, refresh: refreshToken }));
                localStorage.setItem('user', JSON.stringify({ email, fullName }));
                
                // Set the flag to indicate that the page has been reloaded
                localStorage.setItem('alreadyReloaded', 'true');

                // Force reload to ensure the app picks up the updated login state
                window.location.reload();
            } else {
                console.error('Error: Missing tokens from the Google callback.');
            }
        } else {
            // If the page has already been reloaded, remove the flag and navigate to the home page
            localStorage.removeItem('alreadyReloaded');
            navigate('/');
        }
    }, [navigate]);

    return (
        <>
        <center>
           <Spinner/>
        </center>
            
        </>
    );
};

export default GoogleCallback;
