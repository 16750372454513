import React, { useContext, useState } from 'react';
import './reportbug.css';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import { Button } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import SendMail from './SendMail';

const ReportBug = () => {
  const {  user } = useContext(AuthContext);
 
  return (
    <div>
       {user && <Navbar/>}
        <div className="report-bug-container">
        <h1 className="report-bug-heading">Report a Bug</h1>
        <p className="report-bug-paragraph">
            If you encounter any issues or bugs, please let us know by filling out the form below. Your feedback helps us improve the application.
        </p>
        {/* <form className="report-bug-form" onSubmit={handleSubmit}>
            <label htmlFor="name" className="form-label">Name:</label>
            <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-input"
            required
            />

            <label htmlFor="email" className="form-label">Email:</label>
            <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            required
            />

            <label htmlFor="bugDescription" className="form-label">Bug Description:</label>
            <textarea
            id="bugDescription"
            name="bugDescription"
            value={formData.bugDescription}
            onChange={handleChange}
            className="form-textarea"
            rows="4"
            required
            ></textarea>

            <label htmlFor="stepsToReproduce" className="form-label">Steps to Reproduce:</label>
            <textarea
            id="stepsToReproduce"
            name="stepsToReproduce"
            value={formData.stepsToReproduce}
            onChange={handleChange}
            className="form-textarea"
            rows="4"
            ></textarea>

            <label htmlFor="screenshot" className="form-label">Upload Screenshot (Optional):</label>
            <input
            type="file"
            id="screenshot"
            name="screenshot"
            onChange={handleChange}
            className="form-file"
            />

            <Button type="submit" className="form-button">Submit Bug Report</Button>
        </form> */}
        <SendMail/>
        </div>
        <MyFooter/>
    </div>
  );
};

export default ReportBug;
