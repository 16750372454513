import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './aboutus.css';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { API } from '../api-service';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';


const AboutUs = () => {
  const { user,authTokens } = useContext(AuthContext);
  const token = authTokens?.access;
  // console.log("Token from about us page: ",token)
  const [aboutData, setAboutData] = useState(null);
  const [heroTitle, setHeroTitle] = useState(null);
  const [heroContent, setHeroContent] = useState(null);
  const [missionTitle, setMissionTitle] = useState(null);
  const [missionContent, setMissionContent] = useState(null);
  const [storyTitle, setStoryTitle] = useState(null);
  const [storyContent, setStoryContent] = useState(null);

  const navigate1 = useNavigate();

  const gotoHomePage = () => {
    navigate1('/');
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const scrollToMission = () => {
    window.scrollTo({
      top: document.getElementById('our-mission').offsetTop,
      behavior: 'smooth'
    });
  };

  const navigate = (path) => {
    window.location.href = path;
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.getAboutUsContent( {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
        setAboutData(response);
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, []);
  
  

  // console.log("all data ",aboutData)

  const getAboutUsContents = () => {
    // console.log("all data in the function: ",aboutData)
    if(aboutData?.length>0) {
      // console.log("all data in if-else: ",aboutData)
        const report = aboutData ? aboutData : null;
        if(report.length != 0) {
            setHeroTitle(report[0].hero_title == null ? null : report[0].hero_title);
            console.log("Hero Title: ", report[0].hero_title)
            setHeroContent(report[0].hero_content == null ? null : report[0].hero_content);
            setMissionTitle(report[0].mission_title == null ? null : report[0].mission_title);
            setMissionContent(report[0].mission_content == null ? null : report[0].mission_content);
            setStoryTitle(report[0].story_title == null ? null : report[0].story_title);
            setStoryContent(report[0].story_content == null ? null : report[0].story_content);            
        }
        else {
            setHeroTitle(null);
            setHeroContent(null);
            setMissionTitle(null);
            setMissionContent(null);
            setStoryTitle(null);
            setStoryContent(null);
        }
    }
  }

  useEffect(() => {
    getAboutUsContents();
  }, [aboutData]);

  if (!aboutData) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      
     {
      user && <Navbar />
     }
      

      {/* Hero Section */}
      <section className="hero">
        <Button onClick={gotoHomePage}>Got to Homepage</Button>
        <div className="hero-content">
          <h1>{heroTitle}</h1>
          <p>{heroContent}</p>
          <button onClick={scrollToMission}>Learn More</button>
        </div>
      </section>

      {/* Our Mission Section */}
      <section id="our-mission" className="our-mission">
        <h2>{missionTitle}</h2>
        <p>{missionContent}</p>
        
      </section>

      {/* Our Story Section */}
      <section className="our-story">
        <h2>{storyTitle}</h2>
        <p>{storyContent}</p>
      </section>

      {/* Call to Action Section */}
      <section className="call-to-action">
        <Button onClick={() => navigate('')}>Join Us</Button>
      </section>
      <MyFooter/>
    </div>
  );
};

export default AboutUs;
