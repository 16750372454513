import './ComponentsCSS/footer.css';
import footer_qr_code from '../assets/footer_qr_code.png';
import footer_google_play from '../assets/footer_google_play.png';
import { Link } from 'react-router-dom';

function MyFooter() {
    return (
        <div className="footer-container">


            <footer className="bg-green-400 dark:bg-gray-900">
                <div className="mx-auto w-full max-w-screen-xl">
                    <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
                        <div>
                            {/* <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Company</h2> */}
                            <ul className="text-white-500 dark:text-gray-400 font-medium gap-4">
                                <li >Daily Report</li>
                                <li >48/1 A, Purana Paltan,</li>
                                <li className="mb-4">Dhaka 1000, Bangladesh</li>
                                <li className="mb-4">info@amardailyreport.com</li>
                                {/* <li className="mb-4">
                    <a href="#" className="hover:underline">Address</a>
                </li> */}
                                {/* <li className="mb-4">
                    <a href="#" className="hover:underline">Social</a>
                </li> */}
                            </ul>
                        </div>
                        <div>
                            {/* <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Help center</h2> */}
                            <ul className="text-white-500 dark:text-gray-400 font-medium">
                                {/* <li className="mb-4">
                    <Link to="/" className="hover:underline">Sitemap</Link>
                </li> */}
                                <li className="mb-4">
                                    <Link to="/aboutus" className=" hover:underline">
                                        About Us
                                    </Link>
                                </li>
                                {/* <li className="mb-4">
                    <Link to="/usagerights" className="hover:underline">Usage Rights</Link>
                </li> */}
                                <li className="mb-4">
                                    <Link to="/termsandconditions" className="hover:underline">Terms & Conditions</Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="/privacypolicy" className="hover:underline">Privacy Policy</Link>
                                </li>
                                {/* <li className="mb-4">
                    <Link to="/faq" className="hover:underline">FAQ</Link>
                </li> */}
                            </ul>
                        </div>
                        <div>
                            {/* <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2> */}
                            <ul className="text-white-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    <Link to="/help" className="hover:underline">Help</Link>
                                </li>
                                {/* <li className="mb-4">
                    <Link to="/feedback" className="hover:underline">Feedback</Link>
                </li>
                <li className="mb-4">
                    <Link to="/reportabug" className="hover:underline">Report A Bug</Link>
                </li> */}
                                {/* <li className="mb-4">
                    <a to="#" className="hover:underline">Language</a>
                </li> */}
                                <li className="mb-4">
                                    <Link to="/contactus" className="hover:underline">Contact Us
                                    </Link>
                                </li>
                                <li className="mb-4">
                                    <Link to="https://www.bjilibrary.com/" target='#' className="hover:underline">bjilibrary.com</Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            {/* <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Download</h2> */}
                            <ul className="text-gray-500 dark:text-gray-400 font-medium">
                                <li className="mb-4">
                                    {/* <a href="#" className="hover:underline">iOS</a> */}
                                    <img src={footer_qr_code} alt='footer' />
                                </li>
                                <li className="mb-4" style={{ cursor: 'pointer' }}>
                                    <Link to="https://play.google.com/store/games?device=phone" className="hover:underline"><img src={footer_google_play} alt='footer_google' /></Link>

                                </li>
                                {/* <li className="mb-4">
                    <a href="#" className="hover:underline">Windows</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">MacOS</a>
                </li> */}
                            </ul>
                        </div>

                    </div>
                    

                </div>
            </footer>
            <div className="px-4 py-6 bg-green-800 dark:bg-gray-700 md:flex md:items-center md:justify-between">
                        <span className="text-sm text-white dark:text-gray-300 sm:text-center">Copyright © 2024 <a href="https://amardailyreport.com/" target='#'>DailyReport</a>. All Rights Reserved.
                        </span>
                        <div className="flex mt-4 sm:justify-center md:mt-0 space-x-5 rtl:space-x-reverse">
                            <Link to="https://www.facebook.com/BJI.Official" target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                    <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
                                </svg>
                                <span className="sr-only">Facebook page</span>
                            </Link>
                            {/* <a href="#" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                        <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z"/>
                    </svg>
                  <span className="sr-only">Discord community</span>
              </a> */}
                            <Link to="https://x.com/BJI_Official" target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                    <path fillRule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clipRule="evenodd" />
                                </svg>
                                <span className="sr-only">Twitter page</span>
                            </Link>
                            <Link to="https://www.instagram.com/" target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                {/* <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z" clipRule="evenodd"/>
                  </svg> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 20 20">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                </svg>
                                <span className="sr-only">GitHub account</span>
                            </Link>
                            {/* <a href="#" className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                  <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z" clipRule="evenodd"/>
                </svg>
                  <span className="sr-only">Youtube Account</span>
              </a> */}
                            <Link to="https://www.youtube.com/@BJIOfficial" target='#' className="text-gray-400 hover:text-gray-900 dark:hover:text-white">
                                <svg className="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M23.49 6.24c-.28-1.05-1.06-1.87-2.11-2.14-1.85-.31-9.33-.31-9.33-.31s-7.48 0-9.33.31c-1.05.27-1.83 1.09-2.11 2.14-.28 1.03-.28 3.2-.28 3.2s0 2.17.28 3.2c.28 1.05 1.06 1.87 2.11 2.14 1.85.31 9.33.31 9.33.31s7.48 0 9.33-.31c1.05-.27 1.83-1.09 2.11-2.14.28-1.03.28-3.2.28-3.2s0-2.17-.28-3.2zM9.54 15.37v-6.74l6.43 3.37-6.43 3.37z" />
                                </svg>
                                <span className="sr-only">YouTube Account</span>
                            </Link>


                        </div>
                    </div>
        </div>

    );
}

export default MyFooter;