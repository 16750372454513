import React, { useContext } from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './usagerights.css'
import AuthContext from '../context/AuthContext';

const UsageRights = () => {
  const {  user } = useContext(AuthContext);

  return (
    <div>
       {user && <Navbar/>}
      {/* Introduction Section */}
      <div className='usage-section-container'>
        
      <section className="usage-section">
        <h1>Usage Rights</h1>
        <p>This page outlines the terms under which you may use the content provided on this website.</p>
      </section>

      {/* Content Usage Section */}
      <section className="usage-section">
        <h2>Content Usage</h2>
        <p>The content on this website, including text, images, and videos, is provided for personal use. You may not reproduce, distribute, or use the content for commercial purposes without prior written consent from the website owner.</p>
      </section>

      {/* Intellectual Property Section */}
      <section className="usage-section">
        <h2>Intellectual Property</h2>
        <p>All intellectual property rights in the content on this website are owned by or licensed to us. You are granted a limited, non-exclusive, non-transferable license to use the content for personal, non-commercial purposes only.</p>
      </section>

      {/* Limitations of Use Section */}
      <section className="usage-section">
        <h2>Limitations of Use</h2>
        <p>You may not use the content on this website for any unlawful purpose or in any manner that could damage, disable, or impair the website or interfere with any other party's use of the website.</p>
      </section>

      {/* User-Generated Content Section */}
      <section className="usage-section">
        <h2>User-Generated Content</h2>
        <p>If you submit any content to our website, you grant us a worldwide, perpetual, irrevocable, royalty-free license to use, reproduce, and distribute that content. You are responsible for ensuring that the content you submit does not violate any third-party rights.</p>
      </section>

      {/* Disclaimer Section */}
      <section className="usage-section">
        <h2>Disclaimer</h2>
        <p>We do not guarantee the accuracy, completeness, or usefulness of any content on this website. The content is provided "as is" without any warranties of any kind, either express or implied.</p>
      </section>

      {/* Contact Information Section */}
      <section className="usage-section">
        <h2>Contact Information</h2>
        <p>If you have any questions about these Usage Rights, please contact us at <a href="mailto:info@company.com">info@company.com</a>.</p>
      </section>

      </div>
      
      <MyFooter/>
    </div>
  );
};

export default UsageRights;
