import Navbar from '../Components/Navbar'
import MyFooter from '../Components/MyFooter'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './monthlyplan.css'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ShareIcon from '@mui/icons-material/Share';
import { Container, Row, Col } from 'react-grid-system';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { API } from '../api-service';
import Swal from 'sweetalert2';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useTranslation } from 'react-i18next';


const MonthlyPlan = () => {
  const { t,i18n } = useTranslation();
  const { user,authTokens } = useContext(AuthContext);
  const token = authTokens?.access;
  // const token = 'token';
  // const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [month,setMonth] = useState(null);
  const [year,setYear] = useState(null);
  const [quranStudy, setQuranStudy] = useState(null);
  const [quranStudyDays, setQuranStudyDays] = useState(null);
  const [quranMemorize, setQuranMemorize] = useState(null);
  const [quranDars, setQuranDars] = useState(null);
  const [hadithStudy, setHadithStudy] = useState(null);
  const [hadithStudyDays, setHadithStudyDays] = useState(null);
  const [hadithMemorize, setHadithMemorize] = useState(null);
  const [hadithDars, setHadithDars] = useState(null);
  const [bookStudyIslamic, setBookStudyIslamic] = useState(null);
  const [bookStudyOther, setBookStudyOther] = useState(null);
  const [salatJamat, setSalatJamat] = useState(null);
  //const [salatKaja, setSalatKaja] = useState(null);
  const [memberContact, setMemberContact] = useState(null);
  const [workerContact, setWorkerContact] = useState(null);
  const [distributionIslamic, setDistributionIslamic] = useState(null);
  const [distributionOther, setDistributionOther] = useState(null);
  const [meetDawahTarget, setMeetDawahTarget] = useState(null);
  const [meetWorker, setMeetWorker] = useState(null);
  const [meetMember, setMeetMember] = useState(null);
  const [timeSpend, setTimeSpend] = useState(null);
  const [selfCriticism, setSelfCriticism] = useState(null);
  const [familyMeeting, setFamilyMeeting] = useState(null);
  const [socialWork, setSocialWork] = useState(null);
  const [travel, setTravel] = useState(null);
  const [debt, setDebt] = useState(null);
  const [report, setReport] = useState(null);
  const [comment, setComment] = useState('');

  const [gotplan, setGotplan] = useState(false);
  const [allplans, setAllplans] = useState([]);
  const [planid, setPlanid] = useState(0);
  const minimumDate=new Date('2022-01-01');
  const maximumDate=new Date('2122-01-01');

  const downloadPdf = () => {
    const input = document.querySelector('.monthly-plan-middle-part');
    if (!input) return;

    // Temporarily apply styles to ensure black text
    input.style.color = 'black';
    input.querySelectorAll('*').forEach(el => {
      el.style.color = 'black';
    });

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('monthly-plan.pdf');

      // Revert the styles back after PDF generation
      input.style.color = '';
      input.querySelectorAll('*').forEach(el => {
        el.style.color = '';
      });
    });
  };

  const getMonthName = (date) => {
    return date.toLocaleString('default', { month: 'long' });
  };
  
  
  const generatePdf = () => {
    const doc = new jsPDF();
  
    // Get the current month and year
    const currentMonth = getMonthName(selectedDate); // Example: 'September'
    const currentYear = selectedDate.getFullYear();  // Example: 2024
  
    // Define the title of the report with the month and year
    const title = `Monthly Plan - ${currentMonth} ${currentYear}`;
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');  // Set font to bold
    doc.text(title, 15, 15);
  
    // Date
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');  // Set font back to normal
    doc.text(`Date: ${selectedDate.toLocaleDateString()}`, 15, 25);
  
    // Define the custom layout text for the data
    const reportContent = [
      { 
        label: 'Quran (Number):', 
        content: `Days-${quranStudyDays}  |   Study-${quranStudy}  |   Memorization-${quranMemorize}  |   Dars-${quranDars}` 
      },
      { 
        label: 'Hadith (Number):', 
        content: `Days-${hadithStudyDays}  |   Study-${hadithStudy}  |   Memorization-${hadithMemorize}  |   Dars-${hadithDars}` 
      },
      { 
        label: 'Literature (Page Number):', 
        content: `Islamic-${bookStudyIslamic}  |   Others-${bookStudyOther}` 
      },
      { 
        label: 'Namaz:', 
        content: `Jamat-${salatJamat}` 
      },
      { 
        label: 'Contact:', 
        content: `Worker Contact-${workerContact}  |   Member Contact-${memberContact}` 
      },
      { 
        label: 'Distribution:', 
        content: `Islamic-${distributionIslamic}  |   Others-${distributionOther}` 
      },
      { 
        label: 'Meet:', 
        content: `Dawah Target-${meetDawahTarget}  |   Worker-${meetWorker}  |   Member-${meetMember}` 
      },
      { 
        label: 'Org Time:', 
        content: `Time Spend-${timeSpend}` 
      },
      { 
        label: 'Miscellaneous:', 
        content: `Self Criticism-${selfCriticism}  |   Social Work-${socialWork}  |   Debt-${debt}` 
      },
      { 
        label: 'Family & Visit:', 
        content: `Family Meeting-${familyMeeting}  |   Travel-${travel}  |   Report-${report}` 
      }
    ];
  
    // Use autoTable to generate the table
    doc.autoTable({
      startY: 35,  // Start the table after title and date
      head: [['Label', 'Content']],  // Table headers
      body: reportContent.map(item => [item.label, item.content]),  // Table body
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 2,
      },
    });
  
    // Add comments section after the table
    let finalY = doc.lastAutoTable.finalY || 35; // Get the Y position after the table
    doc.setFont('helvetica', 'bold');
    doc.text('Comments:', 15, finalY + 10);
  
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(comment ? comment : ' ', 15, finalY + 20);
  
    // Save the PDF with the month and year in the filename
    doc.save(`Monthly_Plan_${currentMonth}_${currentYear}.pdf`);
    return doc.output('blob');
  };
  

  const generatePdfSharePlan = () => {
    const doc = new jsPDF();
  
    // Get the current month and year
    const currentMonth = getMonthName(selectedDate);
    const currentYear = selectedDate.getFullYear();
  
    // Define the title of the plan with the month and year
    const title = `Monthly Plan - ${currentMonth} ${currentYear}`;
    doc.setFontSize(16);
    doc.text(title, 15, 15);
  
    // Date
    doc.setFontSize(12);
    doc.text(`Date: ${selectedDate.toLocaleDateString()}`, 15, 25);
  
    // Define the report content in the new format
    const reportContentPlan = [
      { label: 'Quran (Number):', content: `Days-${quranStudyDays} | Study-${quranStudy} | Memorization-${quranMemorize} | Dars-${quranDars}` },
      { label: 'Hadith (Number):', content: `Days-${hadithStudyDays} | Study-${hadithStudy} | Memorization-${hadithMemorize} | Dars-${hadithDars}` },
      { label: 'Literature (Page Number):', content: `Islamic-${bookStudyIslamic} | Others-${bookStudyOther}` },
      { label: 'Namaz:', content: `Jamat-${salatJamat}` },
      { label: 'Contact:', content: `Worker Contact-${workerContact} | Member Contact-${memberContact}` },
      { label: 'Distribution:', content: `Islamic-${distributionIslamic} | Others-${distributionOther}` },
      { label: 'Meet:', content: `Dawah Target-${meetDawahTarget} | Worker-${meetWorker} | Member-${meetMember}` },
      { label: 'Org Time:', content: `Time Spend-${timeSpend}` },
      { label: 'Miscellaneous:', content: `Self Criticism-${selfCriticism} | Social Work-${socialWork} | Debt-${debt}` },
      { label: 'Family & Visit:', content: `Family Meeting-${familyMeeting} | Travel-${travel} | Report-${report}` }
    ];
  
    // Use autoTable to generate the table
    doc.autoTable({
      startY: 35,  // Start the table after title and date
      head: [['Label', 'Content']],  // Table headers
      body: reportContentPlan.map(item => [item.label, item.content]),  // Table body
      theme: 'grid',
      styles: {
        fontSize: 10,
        cellPadding: 2,
      },
    });
  
    // Add comments section after the table
    let finalY = doc.lastAutoTable.finalY || 35; // Get the Y position after the table
    doc.setFont('helvetica', 'bold');
    doc.text('Comments:', 15, finalY + 10);
  
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(comment ? comment : ' ', 15, finalY + 20);
  
    // Generate the PDF as a blob
    return doc.output('blob');
  };
  
  const sharePdfPlan = async () => {
    try {
      const pdfBlob = generatePdfSharePlan();
  
      // Create a file object from the blob
      const pdfFile = new File([pdfBlob], `Monthly_Plan_${getMonthName(selectedDate)}_${selectedDate.getFullYear()}.pdf`, {
        type: 'application/pdf',
      });
  
      // Check if the browser supports the Web Share API
      if (navigator.canShare && navigator.canShare({ files: [pdfFile] })) {
        await navigator.share({
          files: [pdfFile],
          title: 'Monthly Plan',
          text: 'Here is my Plan for the month.',
        });
        console.log('Plan shared successfully!');
      } else {
        alert('Sharing not supported on this browser/device.');
      }
    } catch (error) {
      console.error('Error sharing PDF:', error);
    }
  };
  
  

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You are not logged in. Please log in to access your profile.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']){
          // Put your function here
          navigate('/');
        }
      })
      // alert('You are not logged in. Please log in to access your profile.');
      // navigate('/'); // Adjust the route as per your application
    }
  }, [user]);

  useEffect(() => {
    API.getPlans(token,user?.user_id)
    .then( resp =>  setAllplans(resp))
    .catch (error => console.log(error))
  }, [gotplan]);

  const getPlan = () => {
    if(allplans.length>0) {
        const plan = allplans ? allplans.filter((item) => item.month == month && item.year == year && item.user == user.user_id) : null;
        //setP(plan.length);
        if(plan.length != 0) {
            setPlanid(plan[0].id);
            //setQuranStudy(plan.map((item) => item.quranStudy));
            setQuranStudy(plan[0].quranStudy == null ? null : plan[0].quranStudy);
            setQuranStudyDays(plan[0].quranStudyDays == null ? null : plan[0].quranStudyDays);
            setQuranDars(plan[0].quranDars == null ? null : plan[0].quranDars);
            setQuranMemorize(plan[0].quranMemorize == null ? null : plan[0].quranMemorize);

            setHadithStudy(plan[0].hadithStudy == null ? null : plan[0].hadithStudy);
            setHadithStudyDays(plan[0].hadithStudyDays == null ? null : plan[0].hadithStudyDays);
            setHadithDars(plan[0].hadithDars == null ? null : plan[0].hadithDars);
            setHadithMemorize(plan[0].hadithMemorize == null ? null : plan[0].hadithMemorize);

            setBookStudyIslamic(plan[0].bookStudyIslamic == null ? null : plan[0].bookStudyIslamic);
            setBookStudyOther(plan[0].bookStudyOther == null ? null : plan[0].bookStudyOther);
            setSalatJamat(plan[0].salatJamat == null ? null : plan[0].salatJamat);
            //setSalatKaja(plan[0].salatKaja == null ? null : plan[0].salatKaja);
            setMemberContact(plan[0].memberContact == null ? null : plan[0].memberContact);
            setWorkerContact(plan[0].workerContact == null ? null : plan[0].workerContact);

            setDistributionIslamic(plan[0].distributionIslamic == null ? null : plan[0].distributionIslamic);
            setDistributionOther(plan[0].distributionOther == null ? null : plan[0].distributionOther);

            setMeetDawahTarget(plan[0].meetDawahTarget == null ? null : plan[0].meetDawahTarget);
            setMeetMember(plan[0].meetMember == null ? null : plan[0].meetMember);
            setMeetWorker(plan[0].meetWorker == null ? null : plan[0].meetWorker);
            setSocialWork(plan[0].socialWork == null ? null : plan[0].socialWork);
            setFamilyMeeting(plan[0].familyMeeting == null ? null : plan[0].familyMeeting);
            setTimeSpend(plan[0].timeSpend == null ? null : plan[0].timeSpend);
            setSelfCriticism(plan[0].selfCriticism == null ? null : plan[0].selfCriticism);
            setTravel(plan[0].travel == null ? null : plan[0].travel);
            setDebt(plan[0].debt == null ? null : plan[0].debt);
            setReport(plan[0].report == null ? null : plan[0].report);
            setComment(plan[0].comment == null ? '' : plan[0].comment);
            
        }
        else {
            setPlanid(0);
            setQuranStudy(null);
            setQuranStudyDays(null);
            setQuranMemorize(null);
            setQuranDars(null);

            setHadithStudy(null);
            setHadithStudyDays(null);
            setHadithMemorize(null);
            setHadithDars(null);

            setBookStudyIslamic(null);
            setBookStudyOther(null);
            setSalatJamat(null);
            //setSalatKaja(null);
            setMemberContact(null);
            setWorkerContact(null);

            setDistributionIslamic(null);
            setDistributionOther(null);

            setMeetDawahTarget(null);
            setMeetMember(null);
            setMeetWorker(null);
            setSocialWork(null);
            setFamilyMeeting(null);
            setTimeSpend(null);
            setSelfCriticism(null);
            setTravel(null);
            setDebt(null);
            setReport(null);
            setComment('');
        }
    }
}

  useEffect(() => {
      getPlan();
  }, [allplans, month, year]);

  const formateDate = () => {
    const year = selectedDate.getFullYear();
    const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2); // Months are zero indexed, so we add 1
    return `${year}-${month}`;
  };

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   const year = selectedDate.getFullYear();
  //   const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2);
  //   setMonth(month);
  //   setYear(year);
  // };

  useEffect(() => {
    var y = selectedDate.getFullYear();
    var m = ('0' + (selectedDate.getMonth()+1)).slice(-2);
    console.log(m);
    setMonth(m);
    setYear(y);
  }, [selectedDate]);

  const isFutureMonth = (date) => {
    const now = new Date();
    const selectedDate = new Date(date);
    return (
      selectedDate.getFullYear() > now.getFullYear() ||
      (selectedDate.getFullYear() === now.getFullYear() && selectedDate.getMonth() > now.getMonth())
    );
  };

  const prevMonth = () => {
    const prev = moment(selectedDate).subtract(1, 'months');
    if(prev<minimumDate){
        Swal.fire({
          title: "Warning!",
          text: "You cant go beyond that!.",
          icon: "warning"
        });
    }
    else{
      setSelectedDate(new Date(prev));
    }
  }

  const nextMonth = () => {
    const nxt = moment(selectedDate).add(1, 'months');
    if(nxt > maximumDate) {
      Swal.fire({
        title: "Warning!",
        text: "You can't go beyond.",
        icon: "warning"
      });
      
    }
    else{
      setSelectedDate(new Date(nxt));
    }
  }

  const handleSubmit = async () => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You need to log in first.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']){
          // Put your function here
          navigate('/');
        }
      })
      return;
    }
    else{
      setLoading(true);
      const data = {
        user: user.user_id,
        year ,
        month ,
        quranStudy,
        quranMemorize,
        quranStudyDays,
        quranDars,
        hadithStudy,
        hadithMemorize,
        hadithStudyDays,
        hadithDars,
        bookStudyIslamic,
        bookStudyOther,
        salatJamat,
        // salatKaja,
        memberContact,
        workerContact,
        distributionIslamic,
        distributionOther,
        meetDawahTarget,
        meetWorker,
        meetMember,
        timeSpend,
        selfCriticism,
        familyMeeting,
        socialWork,
        travel,
        debt,
        report,
        comment,
      };

      if(planid==0)
      {
        try {
          //const apiUrl = 'http://127.0.0.1:8000/api/plan/';
          API.createPlan(data, token)
            .then( resp => {
              setLoading(false);
                //console.log(resp);
                if(resp.user == user.user_id) {
                  Swal.fire({
                    title: 'Success',
                    text: "Plan successfully Added",
                    icon: 'success',
                    confirmButtonText: 'Ok.'
                  })
                  setGotplan(!gotplan);
                }
                else {
                  Swal.fire({
                    title: 'Warning',
                    text: "Error resp , Plan Not Added.",
                    icon: 'warning',
                    confirmButtonText: 'Ok.'
                  })
                }
            })
            .catch(error => {
              setLoading(false);
                //console.log(error);
                alert(`Submission Error. An error occurred: ${error.message}. Please try again later.`);
            });
          } catch (error) {
            setLoading(false);
            console.error('API Error:', error);
            alert('Submission Error. Please try again later.');
          }
      }
      else{
        try {
          API.updatePlan(planid, data, token)
          .then( resp => {
            setLoading(false);
              //console.log(resp);
              //console.log(data);
              //console.log(reportid);
              console.log(" the response in updation : ",resp)
              if(resp.user == user.user_id) {
                Swal.fire({
                  title: 'Success',
                  text: "Plan successfully Updated!",
                  icon: 'success',
                  confirmButtonText: 'Ok.'
                })
                  setGotplan(!gotplan);
              }
              else {
                Swal.fire({
                  title: 'Warning',
                  text: "Oops! Plan Not Updated!",
                  icon: 'warning',
                  confirmButtonText: 'Ok.'
                })
              }
          })
        } catch (error) {
          setLoading(false);
              console.log("update time error => ",error);
              alert('Error! Plan Not Updated!');
        }

      }
    }
      

    
  };
  

  // Utility function to handle both conversions
  // Utility function to handle both conversions, with null/undefined check
  const convertNumbers = (value, toBengali = true) => {
    if (!value) return '';  // Ensure value is not null or undefined

    const englishToBengaliDigits = {
      '0': '০', '1': '১', '2': '২', '3': '৩', '4': '৪', '5': '৫',
      '6': '৬', '7': '৭', '8': '৮', '9': '৯',
    };

    const bengaliToEnglishDigits = {
      '০': '0', '১': '1', '২': '2', '৩': '3', '৪': '4',
      '৫': '5', '৬': '6', '৭': '7', '৮': '8', '৯': '9',
    };

    const digitsMap = toBengali ? englishToBengaliDigits : bengaliToEnglishDigits;
    
    // Ensure that `value` is a string before calling `split`
    return value.toString().split('').map(digit => digitsMap[digit] || digit).join('');
  };


  const isBengali = i18n.language === 'bn'; // Check if the current language is Bengali

  const handleInputChange = (e, setter) => {
    let value = e.target.value;

    // Convert Bengali digits to English internally, if the language is Bengali
    if (isBengali) {
      value = convertNumbers(value, false); // Convert Bengali to English for internal use
    }

    // Allow only numeric input (in either Bengali or English)
    if (/^\d*$/.test(value)) {
      setter(value); // Update state
    }
    if(setter === setTimeSpend){
      if (/^\d*\.?\d*$/.test(value)) {
        setTimeSpend(value);  // Keep it as a string to allow user to input fractional part
      }
    }

  };



  return (
    <div>
        <Navbar/>
        {!user && (
            <div>
              <p>You are not logged in. Please log in to access your profile.</p>
              <Button variant="primary" onClick={() => navigate('/')}>Login</Button>
            </div>
        )}
        {user && (
        <div className='monthly-plan-middle-part'>
          <div className='monthly-plan-middle-part-1st-row'>
            <div className='monthly-plan-middle-part-1st-row-title'>{t('plan')}</div>

            <div className='monthly-plan-middle-part-1st-row-lowerpart'>
              {/* <Dropdown options={Month}  placeholder="Month" />
              <Dropdown options={Year}  placeholder="Year" /> */}
              <div className='monthly-plan-middle-part-1st-row-datepicker-part'>
              <button onClick={prevMonth}>&lt;</button>
              <DatePicker
                className='report-summary-middle-part-1st-row-datepicker'
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="MMM''yy"  // Format as "Jan'24"
                showMonthYearPicker
                filterDate={(date) => !isFutureMonth(date)}
              />
              <button onClick={nextMonth}>&gt;</button>
              </div>
              <div2>
                <div22>{t('total-days')}</div22>
                <input
                  className='monthly-plan-middle-part-1st-row-input'
                  type="text"
                  value={isBengali ? convertNumbers(report, true) : report} // Display in Bengali or English
                  onChange={(e) => handleInputChange(e, setReport)}
                  // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                />
              </div2>
              
            </div>
          </div>
          
          <Container  className='monthly-plan-data-part'>
            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('quran')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('days')}
              </Col>
              <Col xs={4} xl md lg={1} sm   >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(quranStudyDays, true) : quranStudyDays} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setQuranStudyDays)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('reading')}
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(quranStudy, true) : quranStudy} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setQuranStudy)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('memorization')}
              </Col>
              <Col xs={4} xl md lg={1} sm >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(quranMemorize, true) : quranMemorize} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setQuranMemorize)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              <Col xs={4} xl md lg={1} sm   className='monthly-plan-data-part-days'>
                {t('dars')}
              </Col>
              <Col xs={4} xl md lg={1} sm >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(quranDars, true) : quranDars} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setQuranDars)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
            </Row>


            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('hadith')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('days')}
              </Col>
              <Col xs={4} xl md lg={1} sm   >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(hadithStudyDays, true) : hadithStudyDays} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setHadithStudyDays)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('reading')}
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(hadithStudy, true) : hadithStudy} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setHadithStudy)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('memorization')}
              </Col>
              <Col xs={4} xl md lg={1} sm >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(hadithMemorize, true) : hadithMemorize} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setHadithMemorize)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              <Col xs={4} xl md lg={1} sm   className='monthly-plan-data-part-days'>
                {t('dars')}
              </Col>
              <Col xs={4} xl md lg={1} sm >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(hadithDars, true) : hadithDars} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setHadithDars)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
            </Row>

            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('literature')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('islamic')}
              </Col>
              <Col xs={4} xl md lg={1} sm   >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(bookStudyIslamic, true) : bookStudyIslamic} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setBookStudyIslamic)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('other')}
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(bookStudyOther, true) : bookStudyOther} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setBookStudyOther)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
            </Row>


            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('namaz')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('jamat')}
              </Col>
              <Col xs={4} xl md lg={1} sm   >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(salatJamat, true) : salatJamat} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setSalatJamat)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />
              </Col>
              
              {/* <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                Qadha
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input className='monthly-plan-data-part-number' type='number'
              value={salatKaja}
              onChange={(e) => setSalatKaja(e.target.value)}
              />
              </Col> */}
              
            </Row>

            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('contact')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('worker')}
              </Col>
              <Col xs={4} xl md lg={1} sm   >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(workerContact, true) : workerContact} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setWorkerContact)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />

              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('ruqon')}
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(memberContact, true) : memberContact} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMemberContact)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />

              </Col>
              
            </Row>


            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('distribution')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('islamic')}
              </Col>
              <Col xs={4} xl md lg={1} sm   >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(distributionIslamic, true) : distributionIslamic} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setDistributionIslamic)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />

              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('other')}
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(distributionOther, true) : distributionOther} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setDistributionOther)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />

              </Col>
              
            </Row>


            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('meet')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('dawah-target')}
              </Col>
              <Col xs={4} xl md lg={1} sm   >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(meetDawahTarget, true) : meetDawahTarget} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMeetDawahTarget)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />

              </Col>
              
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('worker')}
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(meetWorker, true) : meetWorker} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMeetWorker)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />

              </Col>

              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
                {t('ruqon')}
              </Col>
              <Col xs={4} xl md lg={1} sm  >
              <input
                className='monthly-plan-data-part-number'
                type="text"
                value={isBengali ? convertNumbers(meetMember, true) : meetMember} // Display in Bengali or English
                onChange={(e) => handleInputChange(e, setMeetMember)}
                // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
              />

              </Col>
              
            </Row>


            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-topic'>
                {t('org-time')}
              </Col>
              <Col xs={4} xl md lg={1.5} sm  className='monthly-plan-data-part-days'>
              </Col>
              <Col xs={4} xl md lg={1.5} sm   >
              <input 
                  className='monthly-plan-data-part-number' 
                  type='text'
                  value={isBengali ? convertNumbers(timeSpend, true) : timeSpend} // Display in Bengali or English 
                  onChange={(e) => handleInputChange(e, setTimeSpend)}
                  onBlur={() => {
                    // Convert to a float when the user leaves the input field
                    if (timeSpend !== '') {
                      setTimeSpend(parseFloat(timeSpend)); // Convert to number only when necessary
                    }
                  }}
                />
                
              </Col>
              
            </Row>

            <Row debug className='monthly-plan-data-part-1st-row'>
              <Col xs xl md lg={2.5} sm  className='monthly-plan-data-part-miss'>
              {t('misscellaneous')}
              </Col>
              <Col xs xl md lg={2.9} sm>
                <Row debug>
                  <Col xs={4} xl md lg={5.1} sm  className='monthly-plan-data-part-days'>
                    {t('criticism')}
                  </Col>
                  <Col xs={4} xl md lg={1} sm   >
                  <input
                    className='monthly-plan-data-part-number'
                    type="text"
                    value={isBengali ? convertNumbers(selfCriticism, true) : selfCriticism} // Display in Bengali or English
                    onChange={(e) => handleInputChange(e, setSelfCriticism)}
                    // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                  />
                  </Col>

                </Row>
                <Row debug>
                  <Col xs={4} xl md lg={5.1} sm  className='monthly-plan-data-part-days'>
                    {t('social')}
                  </Col>
                  <Col xs={4} xl md lg={1} sm   >
                  <input
                    className='monthly-plan-data-part-number'
                    type="text"
                    value={isBengali ? convertNumbers(socialWork, true) : socialWork} // Display in Bengali or English
                    onChange={(e) => handleInputChange(e, setSocialWork)}
                    // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                  />

                  </Col>

                </Row>
                <Row debug>
                  <Col xs={4} xl md lg={5.1} sm  className='monthly-plan-data-part-days'>
                    {t('debt')}
                  </Col>
                  <Col xs={4} xl md lg={1} sm   >
                  <input
                    className='monthly-plan-data-part-number'
                    type="text"
                    value={isBengali ? convertNumbers(debt, true) : debt} // Display in Bengali or English
                    onChange={(e) => handleInputChange(e, setDebt)}
                    // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                  />

                  </Col>

                </Row>
              </Col>

              <Col xs xl md lg={4} sm>
                <Row debug>
                  <Col xs={4} xl md lg={2.5} sm  className='monthly-plan-data-part-days'>
                    {t('family')}
                  </Col>
                  <Col xs={4} xl md lg={1} sm   >
                  <input
                    className='monthly-plan-data-part-number'
                    type="text"
                    value={isBengali ? convertNumbers(familyMeeting, true) : familyMeeting} // Display in Bengali or English
                    onChange={(e) => handleInputChange(e, setFamilyMeeting)}
                    // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                  />
                  </Col>

                </Row>
                <Row debug>
                  <Col xs={4} xl md lg={2.5} sm  className='monthly-plan-data-part-days'>
                    {t('visit')}
                  </Col>
                  <Col xs={4} xl md lg={1} sm   >
                  <input
                    className='monthly-plan-data-part-number'
                    type="text"
                    value={isBengali ? convertNumbers(travel, true) : travel} // Display in Bengali or English
                    onChange={(e) => handleInputChange(e, setTravel)}
                    // placeholder={isBengali ? 'বাংলা সংখ্যা লিখুন' : 'Enter number'}
                  />
                  </Col>

                </Row>
                <Row debug>
                  <Col xs={4} xl md lg={2.5} sm  className='monthly-plan-data-part-days'>
                    
                  </Col>
                  <Col xs={4} xl md lg={1} sm   >
                  {/* <input className='monthly-plan-data-part-number' type='number'
                  value={report}
                  onChange={(e) => setReport(e.target.value)}
                  /> */}
                  </Col>

                </Row>
              </Col>
              
              
            </Row>


            

            <div className='monthly-plan-data-part-comment'>
              <div1>{t('comment')}</div1>
              <textarea type='text'
              value={comment !== null ? comment : ''} 
              onChange={(e) => setComment(e.target.value)}
              />
            </div>

            <div className='monthly-plan-change-saved' onClick={handleSubmit}>{loading ? t('saving') : planid == 0? t('save') : t('update')}</div>

            <div className='monthly-plan-middle-part-bottom-row'>
                <div className='monthly-plan-middle-part-add-new' onClick={generatePdf} >
                  <DownloadForOfflineIcon/>
                  {t('download')} 
                </div>
                <div className='monthly-plan-middle-part-add-new' onClick={sharePdfPlan}>
                  <ShareIcon/>
                  {t('share')}
                </div>
            </div>

          </Container>
          

          
          
        </div>
        )}
        <MyFooter/>
    </div>
  )
}

export default MonthlyPlan