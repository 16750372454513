import React, { useContext, useState } from 'react';
import './feedback.css';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import { Button } from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import SendMail from './SendMail';

const Feedback = () => {
  const {  user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    feedback: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  

  return (
    <div>
      {user &&  <Navbar/>}
        <div className="feedback-container">
        <h1 className="feedback-heading">Feedback Page</h1>
        <p className="feedback-paragraph">
            We value your feedback and would love to hear from you. Please fill out the form below to share your thoughts with us.
        </p>
        <SendMail/>
        </div>
        <MyFooter/>
    </div>
  );
};

export default Feedback;
