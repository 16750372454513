import { motion, sync } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import MyFooter from '../Components/MyFooter'
import './settings.css';
import ThemeToggle from './ThemeToggle';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import config from '../config';
import { Spinner } from 'react-bootstrap';
import { stringify } from 'postcss';

const Settings = () => {
    const [isNightMode, setIsNightMode] = useState(false);
    const { logoutUser, user, authTokens } = useContext(AuthContext);
    const token = authTokens?.access;


    const [isModalOpen, setIsModalOpen] = useState(false); // State to handle modal visibility
    const [email, setEmail] = useState(""); // State for email input
    const [errorMessage, setErrorMessage] = useState(""); // For error handling
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false)

    const toggleModal = (e) => {
        // e.preventDefault();
        setIsModalOpen(!isModalOpen); // Toggles the modal open/close state
    };

    const handleSave = async (e) => {
        e.preventDefault();
      
        setLoading(true);
      
        axios
          .post(
            `${config.authUrl}request-email-change/`,
            { new_email: email },
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include JWT if required
              },
            }
          )
          .then((response) => {
            setLoading(false);
      
            if (response.status === 200) {
              setSuccessMessage(
                "Email change request submitted successfully! Please check your email for verification."
              );
              setErrorMessage(""); // Clear any previous errors
            } else {
              setErrorMessage(response?.data?.error || "Unknown error occurred.");
              setSuccessMessage(""); // Clear any previous success message
            }
          })
          .catch((error) => {
            setLoading(false);
      
            if (error.response) {
              // Server responded with a status outside 2xx range
              setErrorMessage(error.response.data.error || "Something went wrong.");
            } else if (error.request) {
              // No response received
              setErrorMessage("No response from the server. Please try again.");
            } else {
              // Error setting up the request
              setErrorMessage("Request error. Please try again.");
            }
            setSuccessMessage(""); // Clear any previous success message
          })
          .finally(() => {
            toggleModal(); // Close the modal
          });
      };

      
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setIsNightMode(savedTheme === 'night');
        }
    }, []);

    useEffect(() => {
        document.body.className = isNightMode ? 'night' : 'day';
        localStorage.setItem('theme', isNightMode ? 'night' : 'day');
    }, [isNightMode]);

    const toggleTheme = () => {
        setIsNightMode(!isNightMode);
    };
    return (
        <div>
            <Navbar />
            <div className='theme-changer'>
                <Link to={`/changePass`}>

                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            className="password-reset-button"
                        >
                            Change Password
                        </Button>
                    </motion.div>
                </Link>

                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                >
                    <Button
                        variant="contained"
                        color="success"
                        className="password-reset-button my-2"
                        onClick={toggleModal}
                    >
                        Change Email
                    </Button>
                </motion.div>

                {successMessage && <div className="text-white-500 mt-4">{successMessage}</div>}
                {errorMessage && <div className="text-red-500 mt-4">{errorMessage}</div>}
                {/* Modal */}
                {isModalOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-6 rounded-md shadow-md w-96">
                            <h2 className="text-lg font-semibold mb-4 text-emerald-700">Edit Email</h2>
                            <input
                                type="email"
                                name="new_email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter new email"
                                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-500 mb-4"
                            />
                            <div>
                            {successMessage && <div className="text-green-500 mt-4">{successMessage}</div>}
                            {errorMessage && <div className="text-red-700  mt-4">{errorMessage}</div>}    
                             </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={toggleModal}
                                    className="px-4 py-2 text-gray-600 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none"
                                >
                                    Cancel
                                </button>
                               
                                <button
                                    onClick={(e) => handleSave(e)}
                                    type='submit'
                                    className="px-4 py-2 text-white bg-green-500 rounded-md hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-teal-500"
                                >
                                  {
                                    loading? ( <Spinner animation="border" />):" Verify"
                                  }
                                   
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {/* <ThemeToggle isNightMode={isNightMode} toggleTheme={toggleTheme} /> */}
            </div>
            <MyFooter />

        </div>
    )
}

export default Settings