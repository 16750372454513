import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsGlobe } from 'react-icons/bs';
import './languageswitcher.css'; // Ensure you import your CSS here

const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { i18n } = useTranslation();

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="language-switcher" ref={dropdownRef}>
      <BsGlobe className="language-switcher-button" onClick={handleToggle} />
      <div className={`language-dropdown ${isOpen ? 'show' : ''}`}>
        <div className="language-dropdown-item" onClick={() => changeLanguage('en')}>English</div>
        <div className="language-dropdown-item" onClick={() => changeLanguage('bn')}>বাংলা</div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
