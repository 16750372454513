import React, { useContext, useState } from 'react';
import './faq.css'
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import AuthContext from '../context/AuthContext';

const FAQ = () => {
  const {  user } = useContext(AuthContext);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is your return policy?",
      answer: "Our return policy allows you to return products within 30 days of purchase. The product must be in its original condition."
    },
    {
      question: "How long does shipping take?",
      answer: "Shipping usually takes between 3-7 business days, depending on your location."
    },
    {
      question: "Do you offer international shipping?",
      answer: "Yes, we offer international shipping to most countries. Shipping costs will vary based on your location."
    },
    {
      question: "How can I track my order?",
      answer: "Once your order is shipped, we will provide you with a tracking number via email."
    },
    {
      question: "Can I cancel my order?",
      answer: "You can cancel your order within 24 hours of placing it. Please contact our support team for assistance."
    }
  ];

  return (
    <div>
       { user && <Navbar/>}
        <div className="faq-container">
        <h1 className="faq-heading">Frequently Asked Questions</h1>
        {faqData.map((item, index) => (
        <div key={index} className="faq-item">
        <div className="faq-question" onClick={() => toggleAccordion(index)}>
            <h2 className="faq-question-title">{item.question}</h2>
            <span className="faq-toggle-icon">
            {activeIndex === index ? '-' : '+'}
            </span>
        </div>
        {activeIndex === index && (
        <div className="faq-answer">
        <p>{item.answer}</p>
        </div>
          )}
        </div>
      ))}
    </div>
    <MyFooter/>
    </div>
  );
};

export default FAQ;
