import React, { useContext, useEffect, useRef, useState } from 'react'
import Navbar from '../Components/Navbar'
import MyFooter from '../Components/MyFooter'
import './target-list.css'
import 'react-dropdown/style.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import ShareIcon from '@mui/icons-material/Share';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import moment from 'moment';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { API } from '../api-service';
import config from '../config'
import { useTranslation } from 'react-i18next';
import autoTable from 'jspdf-autotable';

const TargetList = () => {
  const API_URL = config.apiUrl
  const { user, authTokens } = useContext(AuthContext);
  const token = authTokens?.access;
  // const token = 'token';
  const [loading, setLoading] = useState(false);
  //const { user } = useContext(AuthContext);
  const [data, setData] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [isaddNew, setIsAddNew] = useState(false);
  const [edit, setEdit] = useState([0]);
  const minimumDate = new Date('2022-01-01');
  const navigate = useNavigate();
  const [gotTargetList, setGotTargetList] = useState(false);
  const [allTargetList, setAllTargetList] = useState([]);
  const [targetListId, setTargetListId] = useState(0);
  const [targetedName, settargetedName] = useState(null);
  const [status, setStatus] = useState(1);
  const [datePlan, setDatePlan] = useState(new Date());
  const [dateImplement, setDateImplement] = useState(new Date());
  const [editStates, setEditStates] = useState({});
  const { t } = useTranslation();



  useEffect(() => {
    if (!user) {
      Swal.fire({
        title: 'Warning',
        text: "You are not logged in. Please log in to access your profile.",
        icon: 'warning',
        confirmButtonText: 'Ok.'
      }).then((result) => {
        if (result['isConfirmed']) {
          // Put your function here
          navigate('/');
        }
      })
    }
  }, [user]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isFutureMonth = (date) => {
    const now = new Date();
    const selectedDate = new Date(date);
    return (
      selectedDate.getFullYear() > now.getFullYear() ||
      (selectedDate.getFullYear() === now.getFullYear() && selectedDate.getMonth() > now.getMonth())
    );
  };

  const prevMonth = () => {
    const prev = moment(selectedDate).subtract(1, 'year');
    if (prev < minimumDate) {
      Swal.fire({
        title: "Warning!",
        text: "You cant go beyond that!.",
        icon: "warning"
      });
    }
    else {
      setSelectedDate(new Date(prev));
    }
  }

  const nextMonth = () => {
    const nxt = moment(selectedDate).add(1, 'year');
    if (nxt <= moment()) {
      setSelectedDate(new Date(nxt));
    }
    else {
      Swal.fire({
        title: "Warning!",
        text: "You can't make future plan!",
        icon: "warning"
      });
    }
  }

  const addNew = () => {
    setIsAddNew(!isaddNew);
  }
  // "Worker": "Worker",
  //       "Associate": "Associate",
  //       "Active Associate": "Active Associate",
  //       "Member": "Member",
  const STATUS_MAP = {
    1: 'Worker',
    2: 'Associate',
    3: 'Active Associate',
    4: 'Member',
  };
  const statusOptions = Object.keys(STATUS_MAP).map(key => ({
    value: key,
    label: STATUS_MAP[key]
  }));

  useEffect(() => {

    API.getTargetList(token)
      .then(resp => setData(resp))
      .catch(error => console.log(error))

  }, [isaddNew])

  // console.log("All Data" + JSON.stringify(data))

  //---------------------to add edit button-------------------
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState({ user: user.user_id, targetedName: '', status: 1, datePlan: '', dateImplement: null });
  const inputRef = useRef(null);

  const handleEditClick = (index) => {
    const originalIndex = data.findIndex(tUser => tUser.id === data.filter(tUser => tUser.user === user.user_id)[index].id);

    setEditStates((prevEditStates) => ({
      ...prevEditStates,
      [originalIndex]: true, // Set this specific row to edit mode
    }));

    setEditData({
      user: user.user_id,
      targetedName: data[originalIndex].targetedName,
      status: data[originalIndex].status,
      datePlan: data[originalIndex].datePlan,
      dateImplement: data[originalIndex].dateImplement,
    });
  };



  // console.log(editData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  const handleSaveClick = async (index) => {
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], ...editData };

    try {
      const response = await API.updateTargetList(updatedData[index].id, updatedData[index], token);
      // console.log('API Response:', response);
      setData(updatedData); // Update local state with the updated data
      setGotTargetList(false)
      setEditStates((prevEditStates) => ({
        ...prevEditStates,
        [index]: false
      }));

      Swal.fire({
        title: "Success!",
        text: "Target successfully updated!",
        icon: "success"
      });
    } catch (error) {
      // console.error('Update error:', error);
      Swal.fire({
        title: "Warning!",
        text: "Error updating target!",
        icon: "warning"
      });
    }
  };



  const addNewTargetList = async (index) => {
    const updatedData = [...data];
    updatedData[index] = { ...updatedData[index], ...editData };
    // console.log(updatedData[index])

    try {
      const response = await API.createTargetList(updatedData[index], token);
      // console.log('API Response:', response);
      setData(updatedData); // Update local state with the updated data
      setGotTargetList(false)
      setEditIndex(null); // Exit edit mode
      setIsAddNew(false)
      Swal.fire({
        title: "Success!",
        text: "Target successfully added!",
        icon: "success"
      });
      setIsAddNew(false)
    } catch (error) {
      // console.error('Update error:', error);
      Swal.fire({
        title: "Warning!",
        text: "target is not added",
        icon: "warning"
      });
    }

  }

  const handleDelete = async (id) => {
    try {
      // Confirm deletion
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });

      if (result.isConfirmed) {
        // Call API to delete the item
        await API.deleteTargetList(id, token);

        // Update local state to remove the deleted item
        // console.log('Before update:', data);
        setData(data.filter(item => item.id !== id));
        console.log('After update:', data.filter(item => item.id !== id));


        Swal.fire({
          title: 'Deleted!',
          text: 'Your target has been deleted.',
          icon: 'success',
        });
      }
    } catch (error) {
      // console.error('Delete error:', error);
      Swal.fire({
        title: 'Error!',
        text: 'There was a problem deleting the target.',
        icon: 'error',
      });
    }
  };




  useEffect(() => {
    if (editIndex !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editIndex]);
  //---------------------to add edit button-------------------

  // Base64 string of Noto Sans Bengali (Replace this with the actual base64)
  const notoSansBengaliBase64 = `AAEAAAALAIAAAwAwT1MvMl3U6WQAAAC8AAAAYGNtYXAAAAFoAAAAVAAAAGhnbHlmWlXP4wAAAGYAAAB6
  GhlYWQQNARyAAAAdAAAANmhoZWEIGAVVAAAAHQAAAB4aG10eFfXhWEAAABoAAAACGxvY2EAAAJsAAAA
  FgAAABRtYXhwBoAE9AAAABgAAAAUG25hbWXJDxQvAAAAgAAAAvFwb3N0AAEAqAAAACAAABBwd3cAAw
  ABAAAAAQAAAPmACtSAAAeBwf4AIIAHEEMDQAAAAAwFgADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAA==`;


  //---------------------to active download button-------------
  const exportToPDF = (data) => {
    const doc = new jsPDF();

    // Add the custom Bengali font
    doc.addFileToVFS('NotoSansBengali-Regular.ttf', notoSansBengaliBase64);
    doc.addFont('NotoSansBengali-Regular.ttf', 'NotoSansBengali', 'normal');
    doc.setFont('NotoSansBengali'); // Set the font to Bengali font

    // Define the columns
    const columns = [
      { header: t('name'), dataKey: 'targetedName' },
      { header: t('type'), dataKey: 'status' },
      { header: t('date-p'), dataKey: 'datePlan' },
      { header: t('date-i'), dataKey: 'dateImplement' },
    ];

    // Prepare the data for the table
    const rows = data
      .filter(tUser => tUser.user === user.user_id)
      .map((item) => ({
        targetedName: item.targetedName,
        status: STATUS_MAP[item.status],
        datePlan: item.datePlan,
        dateImplement: item.dateImplement,
      }));

    // Add the table to the PDF
    doc.autoTable({
      columns: columns,
      body: rows,
      theme: 'grid',
      margin: { top: 10 },
    });

    // Save the PDF
    doc.save('table-data.pdf');
  };

  const sharePdf = () => {
    const doc = new jsPDF();

    // Generate the table content
    const share_data = data
      .filter(tUser => tUser.user === user.user_id)
      .map((target, id) => [
        target.targetedName,
        target.status,
        target.datePlan,
        target.dateImplement,
      ]);

    // Add the table to the PDF
    autoTable(doc, {
      head: [['Targeted Name', 'Status', 'Date Planned', 'Date Implemented']],
      body: share_data,
      margin: { top: 10 },
    });

    // Output the PDF as a blob
    const pdfBlob = doc.output('blob');
    const file = new File([pdfBlob], 'target-list.pdf', {
      type: 'application/pdf',
    });

    // Check if the navigator.share API is supported
    if (navigator.share && navigator.canShare({ files: [file] })) {
      navigator
        .share({
          title: 'Syllabus Data',
          files: [file],
        })
        .catch((error) => console.error('Error sharing', error));
    } else {
      alert('Your browser does not support the share feature.');
    }
  };

  //---------------------to active download button-------------

  //---------------------to add new targets--------------------
  useEffect(() => {
    API.getTargetList(token)
      .then(resp => setAllTargetList(resp))
      .catch(error => console.log(error))
  }, [gotTargetList]);


  return (
    <div>
      <Navbar />
      <div className='target-list-middle-part'>

        <div className='target-list-middle-part-1st-row'>
          <div className='target-list-middle-part-1st-row-title'>{t('t-list')}</div>

          <div className='target-list-middle-part-1st-row-lowerpart'>
            <button onClick={prevMonth}>&lt;</button>
            <DatePicker
              className='target-list-middle-part-1st-row-datepicker'
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy"
              showYearPicker
              filterDate={(date) => !isFutureMonth(date)}
            />
            <button onClick={nextMonth}>&gt;</button>
          </div>
        </div>

        <div className='target-list-middle-part-grid'>
          <div className='target-list-middle-part-grid-row'>
            <div className='target-list-middle-part-grid-header1'>{t('name')}</div>
            <div className='target-list-middle-part-grid-header1'>{t('type')}</div>
            <div className='target-list-middle-part-grid-header1'>{t('date-p')}</div>
            <div className='target-list-middle-part-grid-header1'>{t('date-i')}</div>
          </div>

          {data
            .filter(tUser => tUser.user === user.user_id)
            .map((tUser, index) => {
              const originalIndex = data.findIndex(item => item.id === tUser.id);

              return (
                <div className='target-list-middle-part-grid-row' key={tUser.id}>
                  <div className='target-list-middle-part-grid-header2'>
                    {editStates[originalIndex] ? (
                      <input
                        type='text'
                        name='targetedName'
                        value={editData.targetedName}
                        onChange={handleInputChange}
                        className='target-list-middle-part-grid-header-input'

                      />
                    ) : (
                      tUser.targetedName
                    )}
                  </div>
                  <div className='target-list-middle-part-grid-header2'>
                    {editStates[originalIndex] ? (
                      <select
                        name='status'
                        value={editData.status}
                        onChange={handleInputChange}
                        className='target-list-middle-part-grid-header-input'
                      >
                        {statusOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    ) : (
                      STATUS_MAP[tUser.status]
                    )}
                  </div>
                  <div className='target-list-middle-part-grid-header2'>
                    {editStates[originalIndex] ? (
                      <input
                        type='date'
                        name='datePlan'
                        value={editData.datePlan}
                        onChange={handleInputChange}
                        className='target-list-middle-part-grid-header-input'
                      />
                    ) : (
                      moment(tUser.datePlan).format('YYYY-MM-DD')
                    )}
                  </div>
                  <div className='target-list-middle-part-grid-header2'>
                    {editStates[originalIndex] ? (
                      <input
                        type='date'
                        name='dateImplement'
                        value={editData.dateImplement}
                        onChange={handleInputChange}
                        className='target-list-middle-part-grid-header-input'
                      />
                    ) : (
                      tUser.dateImplement ? moment(tUser.dateImplement).format('YYYY-MM-DD') : "Not Implemented"
                    )}
                  </div>
                  <div className='target-list-middle-part-grid-icon' onClick={() => handleDelete(tUser.id)}>
                    <i className="fa fa-trash" aria-hidden="true" />
                  </div>
                  <div className='target-list-middle-part-grid-icon1'>
                    {editStates[originalIndex] ? (
                      <button className="save-button" onClick={() => handleSaveClick(originalIndex)}>
                        <i className="fa fa-save" aria-hidden="true" />
                      </button>
                    ) : (
                      <button className="edit-button" onClick={() => handleEditClick(index)}>
                        <i className="fa fa-edit" aria-hidden="true" />
                      </button>
                    )}
                  </div>
                </div>
              );
            })}


          {isaddNew &&

            <div className='target-list-middle-part-grid-row'>
              <input
                type='text'
                name='targetedName'
                value={data.targetedName}
                onChange={handleInputChange}
                className='target-list-middle-part-grid-header2' placeholder='Target Name' />
              {/* <input  className='target-list-middle-part-grid-header2' placeholder='Type'/> */}
              <select
                name='status'
                value={data.status}
                onInput={handleInputChange}
                className='target-list-middle-part-grid-header2'
              >
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value} >
                    {option.label}
                  </option>
                ))}
              </select>
              <input
                type='date'
                name='datePlan'
                value={data.datePlan}
                onChange={handleInputChange}
                className='target-list-middle-part-grid-header2'
              />
              <input

                type='date'
                name='dateImplement'
                value={data.dateImplement}
                onChange={handleInputChange}
                className='target-list-middle-part-grid-header2'
              />
              {/* <div
                className='target-list-middle-part-grid-header3'
              /> */}
              <button className="save-button" onClick={addNewTargetList}>
                <i className="fa fa-save" aria-hidden="true" />
              </button>
            </div>
          }


          <div className='target-list-middle-part-grid-row'>
            <div className='target-list-middle-part-grid-add-new' onClick={addNew}>
              <AddCircleIcon />
              {t('add-new')}
            </div>
          </div>

          <div className='target-list-middle-part-grid-row'>
            <div className='target-list-middle-part-grid-add-new' onClick={() => exportToPDF(data)} >
              <DownloadForOfflineIcon />
              {t('download')}
            </div>
            <div className='target-list-middle-part-grid-add-new' onClick={sharePdf}>
              <ShareIcon />
              {t('share')}
            </div>
          </div>

        </div>
      </div>
      <MyFooter />
    </div>
  )
}

export default TargetList