import './App.css';
import Home from './Routes/Home';
import DailyReport from './Routes/DailyReport';
import ReportSummary from './Routes/ReportSummary';
import MonthlyPlan from './Routes/MonthlyPlan';
import TargetList from './Routes/TargetList';
import Syllabus from './Routes/Syllabus';
import Signup from './Routes/Signup';
import { HashRouter, Route, Routes } from 'react-router-dom'; // Ensure to import HashRouter
import ProtectedRoute from "./utils/ProtectedRoute";
import { AuthProvider } from './context/AuthContext';
import Account from './Routes/Account';
import Settings from './Routes/Settings';
import Activate from './Routes/Activate';
import PasswordResetRequest from './Components/PasswordResetRequest';
import PasswordResetConfirm from './Components/PasswordResetConfirm';
import ChangePassword from './Components/ChangePassword';
import AboutUs from './Footer/AboutUs';
import ContactUs from './Footer/ContactUs';
import UsageRights from './Footer/UsageRights';
import TermsConditions from './Footer/TermsCondtions';
import PrivacyPolicy from './Footer/PrivacyPolicy';
import FAQ from './Footer/FAQ';
import Help from './Footer/Help';
import Feedback from './Footer/FeedBack';
import ReportBug from './Footer/ReportABug';
import GoogleCallback from './Components/GoogleCallBack';
import VerifyEmailChange from './Components/EmailVerify';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path="/activate/:uid/:token" element={<Activate />} />
            <Route path="/email_change_verify/:uid/:token" element={<VerifyEmailChange />} />
            <Route path='/dailyreport' element={<DailyReport />} />
            <Route path='/reportsummary' element={<ReportSummary />} />
            <Route path='/monthlyplan' element={<MonthlyPlan />} />
            <Route path='/targetlist' element={<TargetList />} />
            <Route path='/syllabus' element={<Syllabus />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/account' element={<Account />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/forget_pass' element={<PasswordResetRequest />} />            
            <Route path="/password_reset_confirm/:uid/:token" element={<PasswordResetConfirm />} />
            <Route path='/changePass' element={<ChangePassword />} />
            <Route path='/aboutus' element={<AboutUs />} />
            <Route path='/contactus' element={<ContactUs />} />
            <Route path='/usagerights' element={<UsageRights />} />
            <Route path='/termsandconditions' element={<TermsConditions />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/help' element={<Help />} />
            <Route path='/feedback' element={<Feedback />} />
            <Route path='/reportabug' element={<ReportBug />} />
            <Route path="/google/callback/" element={<GoogleCallback />} /> {/* Correct usage of element */}

          </Routes>
        </AuthProvider>
      </HashRouter>
    </div>
  );
}

export default App;
