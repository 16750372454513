import "./ComponentsCSS/navbar-style.css";
import logo from "../assets/personal-report-logo.png"
import { NavbarItems } from "./NavbarItems";
import { RiMenuFold4Fill } from "react-icons/ri";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import AuthContext from '../context/AuthContext';
import { BsPersonCircle } from "react-icons/bs";
import { Button } from "react-bootstrap";
import i18n from "../i18next/i18n";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

// import {
//     ArchiveBoxXMarkIcon,
//     ChevronDownIcon,
//     PencilIcon,
//     Square2StackIcon,
//     TrashIcon,
//   } from '@heroicons/react/16/solid'


const Navbar = () => {
    const [state, setState] = useState(false);
    const { logoutUser,user } = useContext(AuthContext);
    const profileMenuRef = useRef(null);
    const profileIconRef = useRef(null);

    //---------------------For Profile Menu------------------
    const [openMenu, setOpenMenu] = useState(false);
    const handleClickMenu = () => {
        setOpenMenu((prev) => !prev);
    };
    const handleClose = () => {
        setOpenMenu(false);
    };

    const handleClickOutside = (event) => {
        if (profileMenuRef.current && !profileMenuRef.current.contains(event.target) &&
            profileIconRef.current && !profileIconRef.current.contains(event.target)) {
            setOpenMenu(false);
        }
    };

    useEffect(() => {
        if (openMenu) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [openMenu]);

    //----------------------For Profile Menu-------------------------
   
    const handleClick = () =>{
        setState(!state)
    }
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
        let path = `../signup`; 
        navigate(path);
    }
    const gotoSettings = () =>{ 
        let path = `../settings`; 
        navigate(path);
    }

    const gotoBugPage = () =>{ 
        let path = `../reportabug`; 
        navigate(path);
    }

    const gotoHelpPage = () =>{ 
        let path = `../help`; 
        navigate(path);
    }

    const gotoHome = () =>{ 
        let path = `../`; 
        navigate(path);
    }
    const gotoAccountPage = () =>{ 
        let path = `/account`; 
        navigate(path);
    }
    const handleLogOut = () =>{ 
        logoutUser();
        navigate('/');
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { t } = useTranslation();
      
    
 
    return(
        <nav className="navbar-items">
            <RiMenuFold4Fill className="menu-icons" onClick={handleClick}/>
            {/* logo part */}
            <div className="navbar-logo-and-title" onClick={gotoHome}>
                <img src={logo} alt="Logo" className="navbar-logo"/> 
                <div className="navbar-title">Daily Report</div>
            </div>
            {/* logo part */}
            

            {/* Menu Part */}
                <ul  className={state ? "nav-menu-active" : "nav-menu"}>
                    {NavbarItems.map((item,index) =>{
                        return(
                            <li key={index} >
                                <Link className={item.cName} to={item.url}>{t(item.title)}</Link>
                            </li>
                        )
                    })}
                    
                    {!user && <Button onClick={routeChange}>Sign Up</Button> }
                    
                </ul>

                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossOrigin="anonymous"></link>

                {/* <div className="navbar-profile-icons">
                    <i className="fas fa-bars"></i>
                </div> */}

                <div className='language-switcher-profile-icon'>

                <LanguageSwitcher />


                <div 
                className="navbar-profile-icons"
                onClick={handleClickMenu}
                ref={profileIconRef}
                >
                {/* <i className="fas fa-bars"/> */}
                <BsPersonCircle/>

                </div>
                </div>
                
                {openMenu &&
                
                <ul
                onClose={handleClose}
                className="navbar-profile-menu-list"
                ref={profileMenuRef}
                >
                    <li onClick={gotoAccountPage}>
                        Account
                    </li>
                    <li onClick={handleClose}>
                        Invite Mentor
                    </li>
                    <li onClick={gotoSettings}>
                        Settings
                    </li>
                    <li onClick={handleClose}>
                        Theme
                    </li>
                    <li onClick={handleClose}>
                        Language
                    </li>
                    <li onClick={handleClose}>
                        Notes
                    </li>
                    <li onClick={gotoHelpPage}>
                        Help
                    </li>
                    <li onClick={gotoBugPage}>
                        Report a Bug
                    </li>
                    <li onClick={handleClose}>
                        Download App
                    </li>
                    {user &&
                    <li onClick={handleLogOut} className="navbar-profile-menu-logout">
                        Logout
                    </li>
                    }
                </ul>
                }
                

                

                

            {/* Menu Part */}


        </nav>
        

    );
}

export default Navbar