import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import MyFooter from '../Components/MyFooter';
import './privacypolicy.css'
import AuthContext from '../context/AuthContext';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { API } from '../api-service';

const PrivacyPolicy = () => {
  const {  user } = useContext(AuthContext);
  const [allPolicy, setAllPolicy] = useState([])

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const response = await API.getPolicy()
        .then((result) => {
          // console.log("all quest ", result)
          setAllPolicy(result);

        })
        
      } catch (error) {
        console.log("Failed to fetch the Question and Answer")
      }
      
    }

    fetchPolicy();
  }, [])

  const navigate = useNavigate();

  const gotoHomePage = () => {
    navigate('/');
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {user &&  <Navbar/>}
        <div className="privacy-container">
      {/* Introduction Section */}
      <section className="privacy-section">
      <Button onClick={gotoHomePage}>Got to Homepage</Button>
        <h1 className="privacy-heading">Privacy Policy</h1>
        <p className="privacy-paragraph">This Privacy Policy describes how we collect, use, and share your personal information when you visit or make a purchase from our website.</p>
      </section>
    {
      allPolicy.map((policy,id)=>(
         <section key={id} className="privacy-section">
        <h2 className="privacy-subheading"> {policy.title} </h2>
        <p className="privacy-paragraph"> {policy.content} </p>
      </section>
      ))
    }

     
    </div>
        <MyFooter/>
    </div>
    
  );
};

export default PrivacyPolicy;
